.services-container {
    align-items: center;
    background: #F8F9FA;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px 0;
}

.services-title {
    color: black;
    font-weight: bold;
    margin-bottom: 30px;
}

.services-values {
    align-items: center;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 50px;
}

.services-values .card-description-behavior {
    display: flex !important;
}

@media screen and (max-width: 1000px) {
    .services-values {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 815px) {
    .services-container {
        height: auto;
        padding: 12vh 20px 5vh;
    }

    .services-title {
        margin-bottom: 20px;
        text-align: center;
    }

    .services-values {
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
}