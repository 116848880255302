.inov2030-container {
    align-items: center;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0;
}

.inov2030-title {
    color: black;
    font-weight: bold;
    margin: 0 30px 20px 30px;
    text-align: center;
}

.inov2030-context {
    display: flex;
}

.inov2030-content {
    align-items: center;
    color: #6c757d;
    font-size: 18px;
    margin: 10px 100px;
    text-align: left;
    flex: 3;
}

.inov2030-values {
    align-items: center;
    color: #6c757d;
    font-size: 22px;
    margin: 0px 100px;
    text-align: right;
    flex: 1;
}

.inov2030-values-highlight {
    font-weight: bold;
    padding-top: 10px;
}

.inov2030-img-header {
    height: 100%;
    object-fit: cover;
}

.inov2030-img-footer {
    align-items: center;
    display: flex;
    max-width: 40%;
}

@media only screen and (max-width: 815px) {
    .inov2030-container {
        height: auto;
        padding: 20px;
    }

    .inov2030-title {
        margin-bottom: 20px;
        text-align: center;
    }

    .inov2030-context {
        flex-direction: column;
    }    

    .inov2030-content {
        font-size: 14px;
        max-width: 90%;
        margin: 10px 20px;
        text-align: center;
    }

    .inov2030-values {
        font-size: 20px;
        margin: 40px 20px;
        text-align: center;
    }

    .inov2030-img-footer {
        max-width: 100%;
    }

}

@media only screen and (max-width: 1500px) {
    .inov2030-img-header {
        width: 100%;
    }
}