.hero-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa; /* Adjust background as needed */
    overflow: hidden; /* Prevents internal overflow */
    position: relative; /* Ensure positioning context for absolute children */
    top: 0; /* Start at the top of the screen */
    z-index: 1; /* Keep it behind the navbar */
}

.hero-background {
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
}

.hero-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: absolute;
}

.hero-icon {
    height: auto;
    width: 50vw;
}

/* Landscape orientation adjustments for mobile */
@media (orientation: landscape) {
    .hero-icon {
        height: 45vh;
        width: auto;
    }
}
