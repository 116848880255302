:root {
    --lg-background-color3: #ffffff;
}

.navbar-text-custom {
    color: var(--lg-font-color-1-hover);
}

.content-minimal {
    height: calc(100vh - 80px);
}

.function-cards {
    align-items: center;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 20px;
}

.main-content {
    width: calc(100% - 240px); /* Adjust the width considering the sidebar width */
}

.sidebar-filter {
    width: 240px; 
    height: calc(100vh - 80px); 
    /*z-index: 1;*/
    overflow-y: auto; 
    position: fixed; 
    top: 80px; 
    right: 0; 
}
