.about-container {
    align-items: center;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5vh 0;
    box-sizing: border-box;
    position: relative;
}

.about-title {
    color: black;
    font-weight: bold;
    margin-bottom: 3vh;
    font-size: 2.5rem;
    text-align: center;
}

.about-content {
    align-items: center;
    color: #6c757d;
    margin: 10px 10%;
    text-align: center;
    font-size: 1rem;
}

.about-content-highlights {
    align-items: center;
    font-weight: bold;
    margin: 10px 10%;
    text-align: center;
    font-size: 1.3rem;
}

.about-key-values {
    align-items: center;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 50px;
}

.about-key-value {
    align-items: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease;
}

.about-key-value:hover {
    transform: translateY(-10px);
}

.about-key-value-icon {
    height: 90px;
    margin-bottom: 10px;
    width: 90px;
}

.about-key-value-title {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.about-key-value-content {
    color: #6c757d;
    font-size: 1rem;
    text-align: center;
}

@media screen and (max-width: 1200px) {
    .about-key-values {
        grid-template-columns: repeat(2, 1fr);
    }

    .about-title {
        font-size: 2rem;
    }

    .about-content, .about-content-highlights {
        font-size: 1.1rem;
        margin: 10px 8%;
    }
}

@media screen and (max-width: 1000px) {
    .about-key-values {
        grid-template-columns: repeat(2, 1fr);
    }

    .about-title {
        font-size: 1.8rem;
    }

    .about-content, .about-content-highlights {
        font-size: 1rem;
        margin: 10px 5%;
    }
}

@media only screen and (max-width: 768px) {
    .about-container {
        height: auto;
        padding: 12vh 20px 5vh;
    }

    .about-title {
        font-size: 1.6rem;
    }

    .about-content, .about-content-highlights {
        font-size: 0.9rem;
        margin: 10px 5%;
    }

    .about-key-values {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .about-key-value {
        justify-content: center;
        text-align: center;
        width: 100%;
        padding: 0 20px;
    }

    .about-key-value-icon {
        width: 60px;
        height: 60px;
    }

    .about-key-value-title {
        font-size: 1.2rem;
    }

    .about-key-value-content {
        font-size: 0.9rem;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 480px) {
    .about-title {
        font-size: 1.4rem;
    }

    .about-content, .about-content-highlights {
        font-size: 0.8rem;
        margin: 5px 5%;
    }

    .about-key-value-icon {
        width: 50px;
        height: 50px;
    }

    .about-key-value-title {
        font-size: 1rem;
    }

    .about-key-value-content {
        font-size: 0.8rem;
    }
}
