.process-container {
    align-items: center;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px 0;
}

.process-title {
    color: black;
    font-weight: bold;
    margin-bottom: 30px;
}

.process-steps {
    max-width: 1080px;
    margin: 0px 50px;
    padding: 0 20px;
    position: relative;
}

.process-center-line {
    position: absolute;
    height: 85%;
    width: 4px;
    background: #F8F9FA;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
}

.process-step {
    display: flex;
}

.process-step-section {
    background: #F8F9FA;
    border-radius: 10px;
    width: calc(50% - 40px);
    padding: 20px;
    position: relative;
}

.process-step-section {
    opacity: 0;
    transform: translateX(0);
    transition: opacity 0.3s ease-out;
}

@keyframes processStepSectionMoveRight {
    from {
        transform: translateX(-50%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes processStepSectionMoveLeft {
    from {
        transform: translateX(50%);
    }

    to {
        transform: translateX(0);
    }
}

.process-step-section.animate-right {
    opacity: 1;
    animation: processStepSectionMoveRight 2s ease-out forwards;
}

.process-step-section.animate-left {
    opacity: 1;
    animation: processStepSectionMoveLeft 2s ease-out forwards;
}

.process-step-title {
    font-size: 22px;
    font-weight: 600;
}

.process-step-content {
    color: #6c757d;
}

.process-step-icon {
    position: absolute;
    background: #f2f2f2;
    height: 80px;
    width: 80px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #3ea0e2;
    font-size: 17px;
    box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}

/* => LEFT */
.process-step-left {
    justify-content: flex-start;
}

.process-step-icon-left {
    top: 15px;
    right: -80px;
}

/* => RIGHT */
.process-step-right {
    justify-content: flex-end;
}

.process-step-icon-right {
    top: 15px;
    left: -80px;
}


@media(max-width: 815px) {
    .process-container {
        height: auto;
        padding: 12vh 20px 5vh;
    }

    .process-title {
        margin-bottom: 20px;
        text-align: center;
    }

    .process-steps {
        margin: 0px;
    }

    .process-center-line {
        left: 40px;
    }

    .process-step {
        margin: 10px 0 3px 60px;
    }

    .process-step-section {
        padding: 10px;
        width: 100%;
    }
    
    .process-step-section.animate-right,
    .process-step-section.animate-left {
        opacity: 1;
        animation: processStepSectionMoveLeft 2s ease-out forwards;
    }

    .process-step-content {
        margin-bottom: 0px;
    }

    .process-step-icon-left {
        left: -80px;
    }
}