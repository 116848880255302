.energy-dashboard {
  height: 100vh;
  width: 100vw;
  background-color: #f8f9fa;
}

/* Top Metrics Styling */
.energy-top-metrics {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.energy-metric {
  text-align: center;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  margin: 0 10px;
}

.energy-metric h3 {
  margin: 0;
  font-size: 1.5rem;
  color: #007bff;
}

.energy-metric p {
  margin: 0;
  font-size: 0.9rem;
  color: #6c757d;
}

/* Alerts Styling */
.energy-alerts {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.energy-alert {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  margin: 0 10px;
}

.energy-alert-badge {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.energy-alert-badge.energy-serious {
  background-color: #dc3545;
}

.energy-alert-badge.energy-important {
  background-color: #ffc107;
}

.energy-alert-badge.energy-secondary {
  background-color: #17a2b8;
}

.energy-alert-badge.energy-advert {
  background-color: #28a745;
}

/* Charts Styling */
.energy-charts {
  display: flex;
  justify-content: space-between;
}

.energy-line-chart,
.energy-bar-chart {
  flex: 1;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 10px;
}

/*.energy-line-chart h4,
.energy-bar-chart h4 {
  margin-bottom: 15px;
  font-size: 1.2rem;
  color: #343a40;
}*/

.chart-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.energy-date-picker {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.view-selector {
  display: flex;
  gap: 5px;
}

.view-btn {
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f5f5f5;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

.view-btn.active {
  background: #007bff;
  color: #fff;
  border-color: #007bff;
}

.view-btn:hover:not(.active) {
  background: #e9ecef;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.chart-header h4 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.energy-expand-btn {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.energy-modal-tittle {
font-weight: bold;
}

.energy-detail-modal .modal-dialog {
  max-width: 65%;
  width: 65%;
}

.energy-detail-modal .modal-content {
  height: 80vh;
}

.energy-detail-modal .energy-detail-modal-graph canvas {
  width: 100% !important;
  height: 65vh !important; 
}

.energy-detail-modal .modal-header, .energy-detail-modal .modal-body {
  padding: 1em;
}

.energy-detail-modal .energy-modal-tittle {
  font-size: 1.5rem;
}

